module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://vineethvijay.com","contentApiKey":"6be89c7cc899a834bbd7782645"},"production":{"apiUrl":"https://vineethvijay.com","contentApiKey":"6be89c7cc899a834bbd7782645"}},"siteConfig":{"siteUrl":"https://vineethvijay.com","infiniteScroll":true,"postsPerPage":4,"siteTitleMeta":"Vineeth","siteDescriptionMeta":"blog","shortTitle":"Jamify","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":true,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../../packages/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../../packages/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":3},
    },{
      plugin: require('../../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"8af98f2e-a3a0-44dd-85fe-91c6dd3d2f62","server":"https://analytics.atmolabs.org","ignoreLocalhost":true,"detailed":true},
    }]
